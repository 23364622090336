import React from "react";
import Layout from "../components/layout";
import Contact from "../components/sections/contact";
import SEO from "../components/seo";

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <div className="container mx-auto">
        <h1 className="text-center">Contact Me</h1>
        <p className="text-lg lg:w-9/12 mx-auto px-3 text-center">
          You’ve reached the Contact page, how exciting! Feel free to send me a
          message about anything!… code related or just random. Whether it be
          about a specific issue you are having, or if you are curious about
          something in general, I will give you an answer to the best of my
          abilities!
        </p>
        <div className="px-3 py-8 lg:w-8/12 mx-auto">
          <Contact />
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
