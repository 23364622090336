import axios from "axios";
import React from "react";
import { useMutation } from "react-query";

const submitContact = async ({
  firstName,
  lastName,
  email,
  message,
}: {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}) => {
  const { data } = await axios({
    url: "https://wp.componentbasedcarlos.com/graphql",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "post",
    data: {
      query: `
        mutation ContactMutation {
          __typename
          createContactEntry(input: {clientMutationId: "Example", email: "${email}", first_name: "${firstName}", last_name: "${lastName}", message: "${message}"}) {
            data
            success
          }
        }
      `,
    },
  });
  return data;
};

const Contact = () => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [mutate, { status, data, error }] = useMutation(submitContact);

  const onSubmitNewsletter = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await mutate({ firstName, lastName, email, message });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmailChange = React.useCallback(e => {
    setEmail(e.target.value);
  }, []);

  const handleFirstNameChange = React.useCallback(e => {
    setFirstName(e.target.value);
  }, []);

  const handleLastNameChange = React.useCallback(e => {
    setLastName(e.target.value);
  }, []);

  const handleMessageChange = React.useCallback(e => {
    setMessage(e.target.value);
  }, []);

  return (
    <div className="p-8 bg-gray rounded-md shadow-lg">
      {status !== "success" && (
        <form onSubmit={onSubmitNewsletter} className="">
          <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-flow-row gap-5">
            <div className="flex flex-col">
              <label
                htmlFor="contact-first-name"
                className="pb-1 text-darkgray"
              >
                First Name
              </label>
              <input
                className="py-2 px-3 border border-solid border-darkgray rounded-md"
                id="contact-first-name"
                name="contact-first-name"
                value={firstName}
                onChange={handleFirstNameChange}
                type="text"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="contact-last-name" className="pb-1 text-darkgray">
                Last Name
              </label>
              <input
                className="py-2 px-3 border border-solid border-darkgray rounded-md"
                id="contact-last-name"
                name="contact-last-name"
                value={lastName}
                onChange={handleLastNameChange}
                type="text"
                required
              />
            </div>
          </div>
          <div className="flex flex-col pt-4">
            <label htmlFor="contact-email" className="pb-1 text-darkgray">
              Email
            </label>
            <input
              className="py-2 px-3 border border-solid border-darkgray rounded-md"
              id="contact-email"
              name="contact-email"
              value={email}
              onChange={handleEmailChange}
              type="email"
              required
            />
          </div>
          <div className="flex flex-col pt-4">
            <label htmlFor="contact-message" className="pb-1 text-darkgray">
              Message
            </label>
            <textarea
              className="py-2 px-3 border border-solid border-darkgray rounded-md"
              id="contact-message"
              name="contact-message"
              value={message}
              onChange={handleMessageChange}
              required
            />
          </div>
          <button
            type="submit"
            disabled={status === "loading"}
            className="btn btn-red mt-8 disabled:opacity-50"
          >
            {status === "loading" ? "Loading" : "Submit"}
          </button>
        </form>
      )}
      {status === "success" && (
        <p className="text-xl text-scarlet flex flex-col items-center justify-center h-full">
          Thanks for reaching out! I'll try to get back to you as soon as I can.
        </p>
      )}
    </div>
  );
};

export default Contact;
